<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit Menu</b>
      <b v-else>Create Menu</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Parent</label>
        <multiselect
          v-model="parentMenu.selected"
          :options="parentMenu.options"
          placeholder="Type to search"
          track-by="display_name"
          label="display_name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.display_name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.display_name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Display Name"
          v-validate="'required'"
          name="DisplayName"
          v-model="data.displayName"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('DisplayName')"
        >{{ errors.first('DisplayName') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Path"
          v-validate="'required'"
          name="Path"
          v-model="data.path"
        />
        <span class="text-danger text-sm" v-show="errors.has('Path')">{{ errors.first('Path') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Icon</label>
        <v-select label="name" :value="data.icon" :options="paginated" @search="query => options.search = query" placeholder="Select icon" @input="setSelected" :filterable="false">
          <template slot="option" slot-scope="option">
            <div style="align-items: center;"><i class="fas" :class="option.class"></i><span style="margin-top: 1%; margin-left: 2%">{{option.name}}</span></div>
          </template>
          <li slot="list-footer" class="pagination">
            <button @click="options.offset -= 10" :disabled="!hasPrevPage">Prev</button>
            <button @click="options.offset += 10" :disabled="!hasNextPage">Next</button>
          </li>
        </v-select>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Active</label>
        <vs-switch v-model="data.active" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  props: {
    id: {
      type: Number
    }
  },
  created() {
    this.getIcon();
  },

  data() {
    return this.initialState();
  },
  methods: {
    setSelected(value) {
      this.data.icon = value.class;
      this.data.icon_name = value.name;
      // console.log(value.class)
    },
    initialState() {
      return {
        menuId: null,
        options: {
          icons: [],
          search: '',
          offset: 0,
          limit: 10,
        },
        baseUrl: "/api/v1/setting/menu",
        data: {
          parentId: null,
          name: "",
          displayName: "",
          path: "",
          icon: "",
          icon_name: "",
          active: true,
          sort: null
        },
        parentMenu: {
          selected: null,
          options: []
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        parent_id: this.data.parentId,
        name: this.data.name,
        display_name: this.data.displayName,
        path: this.data.path,
        icon: this.data.icon,
        active: this.data.active,
        sort: this.data.sort,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Menu Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Menu Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.parentId = resp.data.parent_id;
            this.data.name = resp.data.name;
            this.data.displayName = resp.data.display_name;
            this.data.path = resp.data.path;
            this.data.icon = resp.data.icon;
            this.data.active = resp.data.active;
            this.data.sort = resp.data.sort;
            if (resp.data.parent_id) {
              this.setParentSelected(resp.data.parent_id);
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getIcon() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/icon")
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
              this.options.icons = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getAllDataParent() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "id",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            let options = [];

            const id = this.id;
            resp.data.records.map(
              function(a) {
                if (id != a.id && a.parent_id == 0) {
                  options.push(a);
                }
              },
              id,
              options
            );

            this.parentMenu.options = options;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setParentSelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "/" + id).then(resp => {
        if (resp.status == "success") {
          this.parentMenu.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          console.log(resp.data);
        }
      });
    }
  },
  mounted() {
    this.getAllDataParent();
    if (this.id) {
      this.getData();
    }
  },
  computed: {
    filtered () {
      return this.options.icons.filter(icon => icon.name.includes(this.options.search));
    },
    paginated () {
      return this.filtered.slice(this.options.offset, this.options.limit + this.options.offset);
    },
    hasNextPage () {
      const nextOffset = this.options.offset + 10;
      return Boolean(this.filtered.slice(nextOffset, this.options.limit + nextOffset).length);
    },
    hasPrevPage () {
      const prevOffset = this.options.offset - 10;
      return Boolean(this.filtered.slice(prevOffset, this.options.limit + prevOffset).length);
    }
  },
  watch: {
    id(v) {
      this.getAllDataParent();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "parentMenu.selected": function(val) {
      if (val) {
        this.data.parentId = val.id;
      } else {
        this.data.parentId = null;
      }
    },
    "options.search": function() {
      this.options.offset = 0
    }
  }
};
</script>
<style scoped>
  .pagination {
    display: flex;
    margin: .25rem .25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
</style>
